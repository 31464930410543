import {
  AppTitle as LiftUiAppTitle,
  type AppTitleProps as LiftUiAppTitleProps,
} from '@tbd/lift-ui/components/AppTitle';

// eventually this will be imported from an internationalization / localization file
const APP_TITLE_TEXT = 'LIFT Assessments';

export type AppTitleProps = Omit<LiftUiAppTitleProps, 'title'>;

export function AppTitle({
  color,
  variant = 'component-bold-m',
  sx = {},
}: AppTitleProps) {
  return (
    <LiftUiAppTitle
      color={color}
      variant={variant}
      sx={sx}
      title={APP_TITLE_TEXT}
    />
  );
}

import { Typography, type TypographyProps } from '@mui/material';

export interface AppTitleProps {
  color: 'white' | 'black';
  title: string;
  variant?: TypographyProps['variant'];
  sx?: TypographyProps['sx'];
}

export function AppTitle(props: AppTitleProps) {
  const { color, title, variant = 'component-bold-m', sx = {} } = props;
  return (
    <Typography
      variant={variant}
      noWrap
      component="h1"
      sx={{
        color,
        letterSpacing: '-0.02em',
        fontWeight: 600,
        fontSize: { xs: '14px', md: '18px' },
        lineHeight: { xs: '16px', md: '24px' },
        ...sx,
      }}
    >
      {title}
    </Typography>
  );
}
